import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'array.prototype.fill';
import objectAssign from 'object-assign';
import Promise from 'promise-polyfill';

if (!window.Promise) {
  window.Promise = Promise;
}

Object.assign = objectAssign;
